



























































































































.card {
  padding: 1.5rem;
  flex-direction: initial;
  transition: none;
}
.main {
  margin: 20px auto;
  width: 80%;
  min-width: 800px;
  max-width: 9600px;
  .bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .page {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: var(--gap);
    .qrInfo {
      flex: 1 0 100%;
      text-align: center;
    }
    .qrItem {
      box-sizing: border-box;
      flex: 0 0 auto;
      margin: 0;
      padding: 0;
      font-size: var(--font-size);
      width: calc((100% - (var(--num-each-row) - 1) * var(--gap)) / var(--num-each-row));
      > img {
        width: 100%;
        display: block;
        font-size: 0.625em;
        margin-bottom: 5px;
      }
      > div {
        text-align: center;
        color: #000;
      }
      > .memberCode {
        font-size: var(--font-size-s);
        color: #666;
      }
      > .en {
        letter-spacing: -1.2px;
      }
    }
    &.XS {
      --num-each-row: 16;
      --gap: 1.5rem;
      --font-size: calc(0.5em + 0.5vw);
      --font-size-s: calc(0.4em + 0.25vw);
    }
    &.S {
      --num-each-row: 8;
      --gap: 3rem;
      --font-size: calc(0.5em + 1vw);
      --font-size-s: calc(0.4em + 0.5vw);
    }
    &.M {
      --num-each-row: 4;
      --gap: 6rem;
      --font-size: calc(0.5em + 2vw);
      --font-size-s: calc(0.4em + 1vw);
    }
    &.L {
      --num-each-row: 2;
      --gap: 8rem;
      --font-size: calc(0.5em + 4vw);
      --font-size-s: calc(0.4em + 2vw);
    }
    &.XL {
      --num-each-row: 1;
      --gap: 8rem;
      --font-size: calc(0.5em + 8vw);
      --font-size-s: calc(0.4em + 4vw);
    }
    .emptyItem {
      height: 0 !important;
    }
  }

}
@media print {
  @page {
    margin: 1.5cm;
  }
  #app {
    height: auto !important;
    .main {
      padding: 0;
      margin: 0;
      width: 100%;
      box-shadow: none;
      background-color: #fff;
      .bar {
        display: none;
      }
      .page {
        .qrItem {
          page-break-inside: avoid;
        }
      }
    }
  }
}
